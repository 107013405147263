// jshint strict:false, undef:false, unused:false

/*/
	Crema Craft Init
	Stephen Ginn at Crema Design Studio
	Last Updated on 2023-05-19
/*/

(function () {
	// Crema Checkbox Replacement Helper
	custom_controls();
	
	// Format Certain Input Fields
	document.querySelectorAll("[type=tel]").forEach(el => {
		IMask(el, {mask: '(000) 000-0000'});
	});
	
	// Crema Captcha v4.2
	captcha('[data-processor="craft2"]', {
		type: "craft2",
		valid: function() {
			var form = this, btn = this.querySelector(".btn-submit"),
				token = form.getAttribute("data-token");
			
			// Move debugging info into hidden message field...
			var message = form.querySelector('textarea.input').value;
			message += '\n' + '<hr>' + '\n' + '### Device Profile' + '\n';

			form.querySelectorAll("input.debug").forEach(el => {
				message += '\n' + el.value;
			});
			
			form.querySelector('textarea.output').value = message;
			
			// Enable Form Submission
			form.method = "post";
			form.CRAFT_CSRF_TOKEN.value = token;
			form.action.value = "contactForm/sendMessage";
			btn.classList.remove("disabled");
			btn.disabled = false;
			btn.type = "submit";
		},
		invalid: function() {
			var form = this, btn = this.querySelector(".btn-submit");

			// Disable Form Submission
			form.removeAttribute("method");
			form.CRAFT_CSRF_TOKEN.value = "";
			form.action.value = "";
			btn.classList.add("disabled");
			btn.disabled = true;
			btn.type = "button";
		}
	});
	
	// Crema Captcha 4.3.7
	captcha('.formcarry', {
		valid: function() {
			var form = this, btn = this.querySelector(".btn-submit");
			
			// Enable Form Submission
			btn.classList.remove("disabled");
			btn.disabled = false;
			btn.type = "submit";
		},
		invalid: function() {
			var form = this, btn = this.querySelector(".btn-submit");
			
			// Disable Form Submission
			btn.classList.add("disabled");
			btn.disabled = true;
			btn.type = "button";
		}
	});
	
	// Contact Form Init
	document.querySelectorAll(".formcarry").forEach((form, i) => {
		// Autofill Success Modal
		form.addEventListener("submit", e => {
			var el = document.querySelector("#modal-success .name");
			el.textContent = e.target.name.value;
		});
		
		// Formcarry Form Init
		form.id = "formcarry" + (i + 1);
		
		formcarry({
			form: "Qonfhn8Ayo",
			element: "#" + form.id,
			onSuccess: function(response) {
				$('#modal-success').modal('show');
			},
			onError: function(error) {
				console.error(error);
			}
		});
	});
})();

$(function () {
	// Initialize Tooltips and popovers
	$('[data-toggle="tooltip"],[data-hover="tooltip"]').tooltip();
	$('[data-toggle="popover"],[data-hover="popover"]').popover();

	// Disable inputs on hidden tabs so they can't be submitted...
	$('form .tab-pane').each(function(e) {
		var $tab = $(this);

		if ($tab.hasClass("active") === false) {
			$tab.find("input, select, textarea").prop('disabled', true);
		}
	});
});

// Disable inputs on hidden tabs so they can't be submitted...
$('form .nav a[data-toggle="tab"]').on('shown.bs.tab', function (e) {
	var $tab = $($(e.target).attr("href")),
		$form = $tab.closest("form"),
		$tabs = $form.find(".tab-pane");

	$tabs.find("input, select, textarea").prop('disabled', true);
	$tab.find("input, select, textarea").removeAttr("disabled");
});
